// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutme-jsx": () => import("./../../../src/pages/aboutme.jsx" /* webpackChunkName: "component---src-pages-aboutme-jsx" */),
  "component---src-pages-brew-js": () => import("./../../../src/pages/brew.js" /* webpackChunkName: "component---src-pages-brew-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-brew-active-category-jsx": () => import("./../../../src/templates/brew/ActiveCategory.jsx" /* webpackChunkName: "component---src-templates-brew-active-category-jsx" */),
  "component---src-templates-brew-basic-category-jsx": () => import("./../../../src/templates/brew/BasicCategory.jsx" /* webpackChunkName: "component---src-templates-brew-basic-category-jsx" */),
  "component---src-templates-brew-popular-category-jsx": () => import("./../../../src/templates/brew/PopularCategory.jsx" /* webpackChunkName: "component---src-templates-brew-popular-category-jsx" */)
}

